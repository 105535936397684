export const useCopyToClipboard = () => {
  const { stackError, stackSuccess } = useToastsStore()
  const { isSupported, copy } = useClipboard()

  const copyToClipboard = async (text: string) => {
    if (!isSupported.value) {
      stackError({
        label: { text: 'commons.errors.unknown', needTranslation: true },
        description: { text: 'components.app_modal_wishlist_share.copy_failed', needTranslation: true },
      })
    }
    else {
      await copy(text)
      stackSuccess({
        label: { text: 'components.app_modal_wishlist_share.copy_success', needTranslation: true },
      })
    }
  }

  return { copyToClipboard, isSupported }
}
